<template>

  <b-card
    no-body
    v-if="settings"
  >
    <b-overlay
      :show="show"
      rounded="xl"
      :variant="variant"
      :opacity="opacity"
      :blur="blur"
    >
      <b-card-header>
        <b-card-title>Settings</b-card-title>
      </b-card-header>
      <b-card-body>
        <b-row>
          <b-col
            md=6
            lg=6
            xs=12
          >
            <b-form @submit.prevent>
              <b-row>
                <b-col cols="12">
                  <b-form-group
                    label="Availble Geos"
                    label-for="v-first-name"
                  >
                    <v-select
                      multiple
                      :options="geos"
                      value="name"
                      label="name"
                      v-model="selected"
                    />
                  </b-form-group>
                </b-col>
                <b-col cols="12">
                  <b-form-group
                    label="Exchange: 1$ = VND"
                    label-for="v-email"
                  >
                    <b-form-input
                      id="v-email"
                      v-model="settings.dollar_to_vnd"
                      type="text"
                      placeholder="Dollar to VND"
                    />
                  </b-form-group>
                </b-col>
                <b-col cols="12">
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="primary"
                    class="mr-1"
                    @click="saveSettings"
                  >
                    Save
                  </b-button>
                </b-col>
              </b-row>

            </b-form>
          </b-col>
          <b-col
            md=6
            lg=6
            xs=12
          >
            <b-form @submit.prevent>
              <b-row>

                <b-col cols="12">
                  <b-form-group
                    label="Price per port"
                    label-for="v-first-name"
                  >
                    <b-form-input
                      type="text"
                      id="v-first-name"
                      v-model="settings.price_per_port"
                      placeholder="Price"
                    />
                  </b-form-group>
                </b-col>

                <!-- first name -->
                <b-col cols="12">
                  <b-form-group
                    label="Price per 1000 GB"
                    label-for="v-first-name"
                  >
                    <b-form-input
                      type="text"
                      id="v-first-name"
                      v-model="settings.T1000"
                      placeholder="Price"
                    />
                  </b-form-group>
                </b-col>

                <b-col cols="12">
                  <b-form-group
                    label="Price per 500 GB"
                    label-for="v-first-name"
                  >
                    <b-form-input
                      type="text"
                      id="v-first-name"
                      v-model="settings.T500"
                      placeholder="Price"
                    />
                  </b-form-group>
                </b-col>
                <b-col cols="12">
                  <b-form-group
                    label="Price per 200 GB"
                    label-for="v-first-name"
                  >
                    <b-form-input
                      type="text"
                      id="v-first-name"
                      v-model="settings.T200"
                      placeholder="Price"
                    />
                  </b-form-group>
                </b-col>
                <b-col cols="12">
                  <b-form-group
                    label="Price per 100 GB"
                    label-for="v-first-name"
                  >
                    <b-form-input
                      type="text"
                      id="v-first-name"
                      v-model="settings.T100"
                      placeholder="Price"
                    />
                  </b-form-group>
                </b-col>

                <b-col cols="12">
                  <b-form-group
                    label="Price per 50 GB"
                    label-for="v-first-name"
                  >
                    <b-form-input
                      type="text"
                      id="v-first-name"
                      v-model="settings.T50"
                      placeholder="Price"
                    />
                  </b-form-group>
                </b-col>

                <b-col cols="12">
                  <b-form-group
                    label="Price per 10 GB"
                    label-for="v-first-name"
                  >
                    <b-form-input
                      type="text"
                      id="v-first-name"
                      v-model="settings.T10"
                      placeholder="Price"
                    />
                  </b-form-group>
                </b-col>

                <b-col cols="12">
                  <b-form-group
                    label="Price per 1 GB"
                    label-for="v-first-name"
                  >
                    <b-form-input
                      type="text"
                      id="v-first-name"
                      v-model="settings.T0"
                      placeholder="Price"
                    />
                  </b-form-group>
                </b-col>

                <!-- email -->
              
              </b-row>
            </b-form>
          </b-col>
        </b-row>
      </b-card-body>
    </b-overlay>  
  </b-card>

</template>

<script>

const options = [
  'US',
  'GB',
  'AU',
  'CA',
  'FR',
  'DE',
  'AX',
  'DZ',
  'AS',
  'AD',
  'AO',
  'AI',
  'AQ',
  'AG',
  'AR',
  'AM',
  'AW',
  'AT',
  'AZ',
  'BS',
  'BH',
  'BD',
  'BB',
  'BY',
  'BE',
  'BZ',
  'BJ',
  'BM',
  'BT',
  'BO',
  'BQ',
  'BA',
  'BW',
  'BV',
  'BR',
  'IO',
  'BG',
  'BF',
  'BI',
  'KH',
  'CM',
  'CV',
  'KY',
  'CF',
  'TD',
  'CL',
  'CX',
  'CC',
  'CO',
  'KM',
  'CG',
  'CD',
  'CK',
  'CR',
  'CI',
  'HR',
  'CU',
  'CW',
  'CY',
  'CZ',
  'DK',
  'DJ',
  'DM',
  'DO',
  'EC',
  'EG',
  'SV',
  'GQ',
  'ER',
  'EE',
  'ET',
  'FK',
  'FO',
  'FJ',
  'FI',
  'GF',
  'PF',
  'TF',
  'GA',
  'GM',
  'GE',
  'GH',
  'GI',
  'GR',
  'GL',
  'GD',
  'GP',
  'GU',
  'GT',
  'GG',
  'GN',
  'GW',
  'GY',
  'HT',
  'HM',
  'VA',
  'HN',
  'HK',
  'HU',
  'IS',
  'IQ',
  'IN',
  'ID',
  'IR',
  'IE',
  'IM',
  'IL',
  'IT',
  'JM',
  'JP',
  'JE',
  'JO',
  'KZ',
  'KI',
  'KW',
  'KG',
  'LA',
  'LV',
  'LB',
  'LS',
  'LR',
  'LY',
  'LI',
  'LT',
  'LU',
  'MO',
  'MK',
  'MG',
  'MW',
  'MY',
  'MV',
  'ML',
  'MT',
  'MH',
  'MQ',
  'MR',
  'MU',
  'YT',
  'MX',
  'FM',
  'MD',
  'MC',
  'MN',
  'ME',
  'MS',
  'MA',
  'MZ',
  'MM',
  'NA',
  'NR',
  'NP',
  'NL',
  'NC',
  'NZ',
  'NI',
  'NE',
  'NG',
  'NU',
  'NF',
  'MP',
  'NO',
  'OM',
  'PW',
  'PS',
  'PA',
  'PG',
  'PY',
  'PE',
  'PH',
  'PN',
  'PL',
  'PT',
  'PR',
  'QA',
  'RE',
  'RO',
  'RU',
  'RW',
  'BL',
  'SH',
  'KN',
  'LC',
  'MF',
  'PM',
  'VC',
  'WS',
  'SM',
  'ST',
  'SA',
  'SN',
  'RS',
  'SC',
  'SL',
  'SG',
  'SX',
  'SK',
  'SI',
  'SB',
  'SO',
  'ZA',
  'GS',
  'SS',
  'ES',
  'LK',
  'SD',
  'SR',
  'SJ',
  'SZ',
  'SE',
  'CH',
  'SY',
  'TW',
  'TZ',
  'TH',
  'TL',
  'TG',
  'TK',
  'TO',
  'TT',
  'TN',
  'TR',
  'TC',
  'TV',
  'UG',
  'UA',
  'AE',
  'UM',
  'UY',
  'VU',
  'VE',
  'VN',
  'VG',
  'VI',
  'WF',
  'EH',
  'YE',
  'ZM',
  'ZW',
  'KR',
]

import {
  BCard, BCardHeader, BCardTitle, BCardBody, BMediaBody, BMedia, BMediaAside, BAvatar, BDropdown, BDropdownItem, BCol, BRow,
  BFormGroup, BFormInput, BFormCheckbox, BForm, BButton, BOverlay
} from 'bootstrap-vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
export default {
  components: {
    BCard,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BMediaBody,
    BMedia,
    BMediaAside,
    BAvatar,
    BDropdown,
    BDropdownItem,
    VuePerfectScrollbar,
    vSelect,
    BCol, BRow, BFormGroup, BFormInput, BFormCheckbox, BForm, BButton,BOverlay
  },
  props: {
    data: {
      type: Object,
      default:  ()=>{}
    },
  },
  directives: {
    Ripple,
  },
  data(){
    return {
      // settings: {
      //   price_per_port: 0,
      //   dollar_to_vnd: 0,
      //   geos_avail: []
      // },
      settings: this.data,
      config: {
        type:{
          1: "Balance"
        },
        icon:{
          1: "DollarSignIcon"
        }
      },
      selected:this.data.geos_avail.filter(x=>!x.block),
      geos: this.data.geos_avail,
      variant: 'dark',
      opacity: 0.85,
      blur: '2px',
      show: false

    }
  },
  created(){
    // console.log(this.data)
    // setTimeout(()=>{
    //   this.settings = Object.assign({},this.data)
    //   this.geos = this.data.geos_avail
    //   this.selected = []// this.data.geos_avail.filter(x=>!x.block) 
    //   this.show = false
    // },3000)
    // this.show = false
  },
  methods: {
    saveSettings(){
      console.log(this.selected)
      this.settings.geos_avail.forEach(element => {
          element.block = true
      });

      this.selected.forEach(element => {
        this.settings.geos_avail.find(x=>x.name == element.name).block = false
      })

      


      //console.log(  this.settings.geos_avail)
      // if(this.selected.length > 0){
      //   this.selected.forEach(element => {
      //     this.settings.geos_avail.find(x=>x.name == element.name).block = true
      //   });
      // }else{
      //   this.settings.geos_avail.forEach(element => {
      //     element.block = false
      //   });
      // }
      

      //console.log(this.settings.geos_avail.filter(x=>x.block))
      this.$parent.saveSettings(this.settings)
    },
    getamountVariant(amount){
      if(type == 1)
        return "light-danger"
    },
    formatDateTime(date){
      let options = { year: 'numeric', month: 'numeric', day: 'numeric',
        hour: 'numeric', minute: 'numeric', second: 'numeric',
        hour12: false}
        return new Intl.DateTimeFormat('en-US', options).format(date)
    }
  },  
  setup(){
    const perfectScrollbarSettings = {
      maxScrollbarLength: 5,
      wheelPropagation: false,
    }
    

    return {
      perfectScrollbarSettings,
    }
  }
}
</script>
<style>
  .noti {
    max-height: 400px;
  }
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>