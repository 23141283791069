<template>
  <b-card no-body>
    <b-card-body class="d-flex justify-content-between align-items-center">
      <div class="truncate">
        <h2 class="mb-25 font-weight-bolder">
          {{ statistic }}
        </h2>
        <span>{{ statisticTitle }}</span>
        <span v-if="button != 'none'">
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            size="sm"
            variant="success"
            class="ht-btn ht-btn--outline hire-btn d-none d-xl-block"
            :to="{name: 'add-funds'}"
          >
            Add Funds
          </b-button>
          <!-- <b-link class="ht-btn ht-btn--outline hire-btn d-none d-xl-block" :to="{name: 'add-funds'}">{{button}}</b-link> -->
        </span>
        
      </div>
      <b-avatar
        :variant="`light-${color}`"
        size="45"
      >
        <feather-icon
          size="21"
          :icon="icon"
        />
      </b-avatar>
    </b-card-body>
  </b-card>
</template>

<script>
import { BCard, BCardBody, BAvatar, BLink, BButton } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BCard,
    BCardBody,
    BAvatar,
    BLink,
    BButton
  },
  directives: {
    Ripple
  },
  props: {
    icon: {
      type: String,
      required: true,
    },
    statistic: {
      type: [Number, String],
      required: true,
    },
    statisticTitle: {
      type: String,
      default: '',
    },
    color: {
      type: String,
      default: 'primary',
    },
    button: {
      type: String,
      default: 'none',
    },
  },
}
</script>
